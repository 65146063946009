define("discourse/plugins/discourse-custom-wizard/discourse/templates/components/custom-wizard-no-access", ["exports", "ember-this-fallback/is-component", "ember-this-fallback/this-fallback-helper", "ember-this-fallback/try-lookup-helper", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _isComponent, _thisFallbackHelper, _tryLookupHelper, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div>{{text}}</div>
  <div class="no-access-gutter">
    <button
      class="wizard-btn primary return-to-site"
      {{action "skip"}}
      type="button"
    >
      {{i18n "wizard.return_to_site" siteName=siteName}}
      {{d-icon "sign-out-alt"}}
    </button>
  </div>
  */
  {
    "id": "weskhBWg",
    "block": "[[[10,0],[12],[41,[28,[32,0],[\"text\"],null],[[[8,[39,2],null,null,null]],[]],[[[44,[[28,[37,4],null,[[\"text\"],[[28,[32,1],[\"text\"],null]]]]],[[[1,[52,[30,1,[\"text\"]],[28,[30,1,[\"text\"]],null,null],[28,[32,2],[[30,0],\"text\",\"[\\\"The `text` property path was used in the `discourse/plugins/discourse-custom-wizard/discourse/templates/components/custom-wizard-no-access.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.text}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]\"],null]]]],[1]]]],[]]],[13],[1,\"\\n\"],[10,0],[14,0,\"no-access-gutter\"],[12],[1,\"\\n  \"],[11,\"button\"],[24,0,\"wizard-btn primary return-to-site\"],[24,4,\"button\"],[4,[38,6],[[30,0],\"skip\"],null],[12],[1,\"\\n    \"],[1,[28,[35,7],[\"wizard.return_to_site\"],[[\"siteName\"],[[30,0,[\"siteName\"]]]]]],[1,\"\\n    \"],[1,[28,[35,8],[\"sign-out-alt\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,[28,[32,3],[\"[[\\\"The `siteName` property path was used in the `discourse/plugins/discourse-custom-wizard/discourse/templates/components/custom-wizard-no-access.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.siteName}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"maybeHelpers\"],false,[\"div\",\"if\",\"text\",\"let\",\"hash\",\"button\",\"action\",\"i18n\",\"d-icon\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/templates/components/custom-wizard-no-access.hbs",
    "scope": () => [_isComponent.default, _tryLookupHelper.default, _thisFallbackHelper.default, _deprecationsHelper.default],
    "isStrictMode": false
  });
});