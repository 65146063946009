define("discourse/plugins/email-extension/discourse/components/reply-via-email", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/lib/url", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _url, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ReplyViaEmail extends _component.default {
    // indicates if the button will be prompty displayed or hidden behind the show more button
    static hidden(args) {
      return args.state.currentUser != null;
    }
    static shouldRender(args) {
      return args.post.can_reply_via_email;
    }
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    get folded() {
      return this.args.post.post_folding_status;
    }
    get title() {
      return this.folded ? "discourse_post_folding.expand.title" : "discourse_post_folding.fold.title";
    }
    get icon() {
      return this.folded ? "expand" : "compress";
    }
    replyViaEmail() {
      if (!this.siteSettings.email_extension_reply_by_email_address) {
        return;
      }
      const topic = this.args.post.topic;
      const prefix = this.siteSettings.email_extension_topic_reply_subject_headers.split && this.siteSettings.email_extension_topic_reply_subject_headers.split("|")[0] || "Re:";
      const subject = `${prefix} ${topic?.title || ""}`;
      const address = this.siteSettings.email_extension_reply_by_email_address.replace("%{post_id}", this.args.post.id);
      _url.default.routeTo(`mailto:${address}?subject=${encodeURI(subject)}`);
    }
    static #_2 = (() => dt7948.n(this.prototype, "replyViaEmail", [_object.action]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DButton
          class="reply-via-email"
          ...attributes
          @action={{this.replyViaEmail}}
          @icon="square-envelope"
          @title="email_extension.reply_via_email"
        />
      
    */
    {
      "id": "KsxQ5tJe",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"reply-via-email\"],[17,1]],[[\"@action\",\"@icon\",\"@title\"],[[30,0,[\"replyViaEmail\"]],\"square-envelope\",\"email_extension.reply_via_email\"]],null],[1,\"\\n  \"]],[\"&attrs\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/email-extension/discourse/components/reply-via-email.js",
      "scope": () => [_dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ReplyViaEmail;
});