define("discourse/plugins/discourse-custom-wizard/discourse/templates/components/custom-wizard-date-input", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <Input
    @type={{this.inputType}}
    @value={{readonly this.value}}
    class="date-picker"
    placeholder={{this.placeholder}}
    tabindex={{this.tabindex}}
    {{on "input" (action "onChangeDate")}}
    autocomplete="off"
  />
  
  <div class="picker-container"></div>
  */
  {
    "id": "5uMZF2Uc",
    "block": "[[[8,[39,0],[[24,0,\"date-picker\"],[16,\"placeholder\",[30,0,[\"placeholder\"]]],[16,\"tabindex\",[30,0,[\"tabindex\"]]],[24,\"autocomplete\",\"off\"],[4,[38,2],[\"input\",[28,[37,3],[[30,0],\"onChangeDate\"],null]],null]],[[\"@type\",\"@value\"],[[30,0,[\"inputType\"]],[28,[37,1],[[30,0,[\"value\"]]],null]]],null],[1,\"\\n\\n\"],[10,0],[14,0,\"picker-container\"],[12],[13]],[],false,[\"input\",\"readonly\",\"on\",\"action\",\"div\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/templates/components/custom-wizard-date-input.hbs",
    "isStrictMode": false
  });
});