define("discourse/plugins/email-extension/api-initializers/email-extension", ["exports", "discourse/lib/api", "discourse/plugins/email-extension/discourse/components/reply-via-email"], function (_exports, _api, _replyViaEmail) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _api.apiInitializer)("1.16.0", api => {
    api.includePostAttributes("can_reply_via_email");
    api.registerValueTransformer("post-menu-buttons", _ref => {
      let {
        value: dag,
        context: {
          firstButtonKey,
          lastHiddenButtonKey
        }
      } = _ref;
      if (!api.container.lookup("service:site-settings").email_extension_reply_by_email_address) {
        return;
      }
      dag.add("reply-via-email", _replyViaEmail.default, api.getCurrentUser() ? {
        before: lastHiddenButtonKey
      } : {
        after: firstButtonKey
      });
    });
  });
});