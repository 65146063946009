define("discourse/plugins/discourse-custom-wizard/discourse/templates/components/custom-wizard-similar-topics", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if showTopics}}
    <ul>
      {{#each topics as |topic|}}
        <li>{{custom-wizard-similar-topic topic=topic}}</li>
      {{/each}}
    </ul>
  {{else}}
    <a role="button" class="show-topics" {{action "toggleShowTopics"}}>
      {{i18n "realtime_validations.similar_topics.show"}}
    </a>
  {{/if}}
  */
  {
    "id": "bHjzYvdK",
    "block": "[[[41,[30,0,[\"showTopics\"]],[[[1,\"  \"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"topics\"]]],null]],null],null,[[[1,\"      \"],[10,\"li\"],[12],[1,[28,[35,5],null,[[\"topic\"],[[30,1]]]]],[13],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[11,3],[24,\"role\",\"button\"],[24,0,\"show-topics\"],[4,[38,7],[[30,0],\"toggleShowTopics\"],null],[12],[1,\"\\n    \"],[1,[28,[35,8],[\"realtime_validations.similar_topics.show\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]],[1,[28,[32,0],[\"[[\\\"The `showTopics` property path was used in the `discourse/plugins/discourse-custom-wizard/discourse/templates/components/custom-wizard-similar-topics.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.showTopics}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `topics` property path was used in the `discourse/plugins/discourse-custom-wizard/discourse/templates/components/custom-wizard-similar-topics.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.topics}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"topic\"],false,[\"if\",\"ul\",\"each\",\"-track-array\",\"li\",\"custom-wizard-similar-topic\",\"a\",\"action\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/templates/components/custom-wizard-similar-topics.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});